<template>
    <div>
        <div class="content-header clearfix">
            <h1 class="float-left">
                {{ $t('pages.shares.sellStock') }}
                <small>
                    <slot name="back">
                        <CIcon name="cil-arrow-circle-left"></CIcon>
                        <a href="javascript:void(0)" @click="onCancel">{{ $t('common.back') }}</a>
                    </slot>
                </small>
            </h1>
        </div>
        <CRow>
            <CCol sm="12">
                <Accordion :headerTitle="$t('pages.deposit.recipientOfaccountInformation')" expand>
                    <template v-slot:body>
                        <div v-if="adminBankAccounts != null">
                            <CRow v-for="(item) in adminBankAccounts" v-bind:key="item.id">
                                <CCol sm="2">
                                    <b> {{$t('pages.bank.account')}}: {{ item.bankCode }}</b>
                                </CCol>
                                <CCol sm="6">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label><b>{{ $t('pages.bank.bankName') }}</b></label>
                                        </div>
                                        <div class="col-md-6">
                                            <p><b>{{ item.bankName }}</b></p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label>{{ $t('pages.bank.accountName') }}</label>
                                        </div>
                                        <div class="col-md-6">
                                            <p>{{  item.accountName }}</p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label>{{ $t('pages.bank.accountNo') }}</label>
                                        </div>
                                        <div class="col-md-6">
                                            <p>{{  item.accountNo }}</p>
                                        </div>
                                    </div>
                                </CCol>
                            </CRow>
                        </div>
                    </template>
                </Accordion>
            </CCol>
        </CRow>
        <CRow>
            <CCol sm="12">
                <Accordion :headerTitle="$t('common.generalInfo')">
                    <template v-slot:body>                       
                        <CCard>
                            <CCardBody>
                                <CForm>
                                    <CRow>
                                        <CCol md="6">
                                            <div role="group" class="form-group form-row">
                                                <label class="col-form-label col-sm-3">{{$t('common.price')}} </label>
                                                <div class="col-sm-9">
                                                    <span style="color:crimson;font-weight:bold">{{ $func.formatNumber(currentStockPrice.priceForSell) }} VND / {{$t('pages.shares.oneStock')}}</span>
                                                </div>
                                            </div>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol md="6">
                                            <div role="group" class="form-group form-row">
                                                <label class="col-form-label col-sm-3">{{$t('common.amount')}} </label>
                                                <div class="col-sm-9">
                                                    <span style="color:crimson;font-weight:bold">{{ $func.formatNumber(amount) }} VND </span>
                                                </div>
                                            </div>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol md="6">
                                            <CInput :placeholder="$t('common.enterQuantity')" v-model="quantity" horizontal @keypress="$func.onlyNumbers" @keyup="handleKeyup">
                                                <template #label>
                                                    <div class="col-sm-3 col-form-label-">
                                                        {{ $t('common.quantity') }}
                                                        <small class="requiredInput">(*)</small>
                                                    </div>
                                                </template>
                                            </CInput>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol md="6">
                                            <CButton type="button" color="primary" @click="onSellStock">{{ $t('pages.shares.sellStock') }}</CButton>
                                        </CCol>
                                    </CRow>
                                </CForm>
                            </CCardBody>
                        </CCard>                        
                    </template>
                </Accordion>               
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import { mapGetters, mapState, mapActions } from 'vuex'
    import i18n from '@/plugins/i18n'   
    import Accordion from '@/components/Accordion.vue'   
    
    export default {
        name: 'SellStock',
		data() {            
			return {                
                horizontal: { label: 'col-3', input: 'col-9' },                       
                quantity: 0,
                amount: 0,
            };
		},
        components: {           
            Accordion
        },
        computed: {
            ...mapGetters('auth', ['user']),           
            ...mapState('bank', ['adminBankAccounts']),
            ...mapState('stockTrading', ['currentStockPrice'])
        },
        methods: {
            ...mapActions('bank', ['getBankAccountOfSystem']),
            ...mapActions('stockTrading', ['getCurrentStockPrice']),

            validator(val) {
                return val ? val.length > 0 : false
            },
            onCancel() {
                this.$router.push('/shares/list');
            },
            handleKeyup() {
                let _quantity = !isNaN(this.quantity) ? this.quantity : 0;
                
                if (this.currentStockPrice != null)
                    this.amount = _quantity * this.currentStockPrice.priceForBuy;
            },

            async onSellStock() {
                if (isNaN(this.quantity) || this.quantity <= 0) {
                    this.$store.dispatch('alert/error', i18n.t('pages.warehouse.enterQuantity'), { root: true });
                    return;
                }
                //notEnoughStockToSell
                
                var retVal = await this.$store.dispatch("stockTrading/addSellStockOrder", { quantity: this.quantity });
                
                if (retVal.success == true) {                   
                    this.$store.dispatch('toast/displayNotification', { text: i18n.t('pages.shares.noticesSellStockSuccess') });

                    this.$router.push('/shares/list');
                }
                else {
                    let message = i18n.t('pages.shares.noticesSellStockUnsuccess');
                    if (retVal.errorMessage != '') {
                        switch (retVal.errorMessage) {
                            case 'invalidquantity': message = i18n.t('pages.shares.noticesBuyOwverLimit'); break;                           
                            case 'owverlimit': message = i18n.t('pages.shares.noticesSellOwverLimit'); break;
                            case 'notenoughstock': message = i18n.t('pages.shares.notEnoughStockToSell'); break;
                            case 'InvalidBankAccount': message = i18n.t('pages.shares.noticesInvalidBankAccount'); break;
                            case 'noticeNoVerifyAccount': message = i18n.t('pages.account.noticeNoVerifyAccount'); break;
                        }
                    }
                    this.$store.dispatch('alert/error', message);
                }
            },            
		},
        mounted() {
            this.getBankAccountOfSystem();
            this.getCurrentStockPrice();
        }
	}
</script>

<style scoped>
    .btn-primary {
        background-color: #3c8dbc;
        border-color: #367fa9;
    }

        .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
            color: #fff;
            background-color: #0062cc;
            border-color: #005cbf;
        }

    .btn-danger:not(:disabled):not(.disabled).active, .btn-danger:not(:disabled):not(.disabled):active, .show > .btn-danger.dropdown-toggle {
        color: #fff;
        background-color: #bd2130;
        border-color: #b21f2d;
    }

    a:hover {
        color: #0056b3;
        text-decoration: none;
    }

    a {
        color: #0056b3;
    }

    .content-header h1 {
        font-size: 1.8rem;
        margin: 0;
    }

    .content-header > h1 {
        margin-bottom: 10px;
    }

    .float-left {
        float: left !important;
    }

    .float-right {
        float: right !important;
    }

    .clearfix::after {
        display: block;
        clear: both;
        content: "";
    }

    .content-header > h1 > small {
        color: #0076bb;
        font-weight: 400;
        margin-left: 6px;
        font-size: 65%;
    }

        .content-header > h1 > small i {
            font-size: .8em;
            padding-right: 2px;
        }

    header {
        background: #3c4b64
    }

    h5 {
        font-size: 1.09375rem;
    }

        h5 a {
            color: #fff;
        }

    .mb-0 {
        margin-bottom: 0 !important;
    }

    .float-right button {
        margin-left: 0.2rem;
    }
</style>